import { UserService } from './../services'
import i18n from './../../../i18n'
import services from '../services/services'

let lastUser = null

/**
 * Get Userdata
 *
 * @returns {object}
 */
function getUserdata () {
  const userdata = JSON.parse(localStorage.getItem('userData')) || lastUser

  // On logout we clean userdata but sometimes event is sended after that
  // So we save value of userdata
  lastUser = userdata
  return userdata
}

/**
 * Create User Channel
 *
 * @returns {String}
 */
function createUserChannel () {
  const userdata = getUserdata()
  return userdata && `$usr_${userdata.id}`
}

/**
 * Get Notification for Message
 *
 * @param {object} message
 *
 * @returns {String}
 */
function getNotificationForMessage (message) {
  if (message.entity['Orderadmin\\Products\\Entity\\AbstractOrder']) {
    return `${i18n.global.tc('New message for order')} ${message.entity['Orderadmin\\Products\\Entity\\AbstractOrder']}, ${i18n.global.tc('shop')} ${message.entity['Orderadmin\\Products\\Entity\\Shop']}`
  }

  return `No notification text for message ${message.id}`
}

const actions = {
  'application.comments.message.saved': ({ commit }, payload) => {
    const userdata = JSON.parse(localStorage.getItem('userData'))

    const message = {
      ...payload.data.update,
      id: payload.entity
    }

    if (userdata && userdata.id !== payload.data.update.author) {
      const data = {
        ...payload,
        message: getNotificationForMessage(message)
      }

      commit('addNotification', data)
      commit('addLocalNotification', data)
    }

    commit('addMessage', message)
  },
  'storage.movements.acceptance.saved': ({ commit, state }, payload) => {
    const fields = [
      'comment',
      'state'
    ]

    if (payload.data.update && (payload.data.update.state === 'new' && Object.keys(payload.data.update).length > 1)) {
      const message = `New PO ${payload.entity}`
      commit('addNotification', message)
      commit('addLocalNotification', { message })
    }

    const update = Object.keys(payload.data.update).reduce((acc, key) => {
      if (fields.includes(key)) {
        acc[key] = payload.data.update[key]
      }

      return acc
    }, {})

    if (state.acceptance && state.acceptance.id === Number(payload.entity)) {
      commit('updateAcceptance', update)
      commit('updateCleanAcceptance', { ...update })
    }

    let hasUpdate = false

    const items = state.acceptances.map(acceptance => {
      if (`${acceptance.id}` === `${payload.entity}`) {
        hasUpdate = true
        return { ...acceptance, ...update }
      }

      return acceptance
    })

    if (hasUpdate) {
      commit('setOrders', items)
    }

    return this
  },
  'products.order.saved': ({ commit, state }, payload) => {
    const fields = [
      'comment',
      'discountPrice',
      'email',
      'orderPrice',
      'paymentState',
      'recipientName',
      'state',
      'stateDescription',
      'totalPrice'
    ]

    if (payload.data.update && ((payload.data.update.state === 'pending' && Object.keys(payload.data.update).length === 1) || (payload.data.update.state === 'pending_queued' && Object.keys(payload.data.update).length > 1))) {
      commit('addOrderNotification', payload.entity)
    }

    const orderUpdate = Object.keys(payload.data.update).reduce((acc, key) => {
      if (fields.includes(key)) {
        acc[key] = payload.data.update[key]
      }

      return acc
    }, {})

    if (state.order && (state.order.id === Number(payload.entity))) {
      commit('updateOrder', orderUpdate)
      commit('updateCleanOrder', { ...orderUpdate })
    }

    let hasUpdate = false

    const orders = state.orders.map(order => {
      if (`${order.id}` === `${payload.entity}`) {
        hasUpdate = true
        const update = { ...order, ...orderUpdate }
        services.order.upsert(update)
        return update
      }

      return order
    })

    if (hasUpdate) {
      commit('setOrders', orders)
    }

    return this
  },
  'products.order.opened': ({ commit }, payload) => {
    return commit('addLockedOrder', payload.entity)
  },
  'products.order.closed': ({ commit }, payload) => {
    return commit('removeLockedOrder', payload.entity)
  },
  'delivery.services.request.saved': ({ commit, state }, payload) => {
    const fields = [
      'state',
      'outExtId',
      'price',
      'trackingNumber',
      'shippedByDocument',
      'senderName',
      'deliveryServiceStatus'
    ]

    const update = Object.keys(payload.data.update || {}).reduce((acc, key) => {
      if (fields.includes(key)) {
        acc[key] = payload.data.update[key]
      }

      return acc
    }, {})

    if (state.deliveryRequest && (state.deliveryRequest.id === Number(payload.entity)) && Object.values(update).length > 0) {
      commit('updateDeliveryRequest', update)
      commit('updateCleanDeliveryRequest', { ...update })
    }

    let hasUpdate = false

    const items = state.deliveryRequests.map(dr => {
      if (`${dr.id}` === `${payload.entity}`) {
        hasUpdate = true
        const updatedValue = { ...dr, ...update }
        services.deliveryRequest.upsert(update)
        return updatedValue
      }

      return dr
    })

    if (hasUpdate) {
      commit('setDeliveryRequests', items)
    }

    return this
  },
  'delivery.services.request.opened': ({ commit }, payload) => {
    return commit('addLockedDeliveryRequest', payload.entity)
  },
  'delivery.services.request.closed': ({ commit }, payload) => {
    return commit('removeLockedDeliveryRequest', payload.entity)
  },
  'notifications.interaction.saved': ({ commit }, payload) => {
    const code = `${payload.data.update.eav['integrations-asterisk-call'].call}`
    const codesWhichRemoveNotification = ['6', '4']

    if (codesWhichRemoveNotification.includes(code)) {
      return commit('removeNotificationByEntity', payload.entity)
    }

    const data = {
      ...payload,
      message: `${i18n.global.tc('Calling from client')} ${payload.data.update.eav['integrations-asterisk-call'].callerid} ${i18n.global.tc('for shop')} ${payload.shop.name}(${payload.shop.id})`
    }

    commit('addNotification', data)
    return commit('addLocalNotification', data)
  },
  'storage.place.opened': ({ commit }, payload) => {
    return commit('addStoragePlaceEvent', payload)
  },
  'storage.tasks.scan.place': ({ commit }, payload) => {
    return commit('addStoragePlaceEvent', payload)
  },
  'storage.place.closed': ({ commit }, payload) => {
    return commit('removeStoragePlaceEvent', payload.entity)
  },
  'storage.picking.task.updated': ({ commit }, payload) => {
    return commit('addStoragePickingTask', payload)
  },
  'storage.picking.task.saved': ({ commit }, payload) => {
    return commit('addStoragePickingTask', payload)
  },
  'storage.tasks.task.updated': ({ commit }, payload) => {
    return commit('addStoragePickingTask', payload)
  },
  'storage.tasks.task.saved': ({ commit }, payload) => {
    return commit('addStoragePickingTask', payload)
  },
  'orderadmin.user.logout': () => {
    UserService.logout()
  },
  'orderadmin.instructions.saved': ({ commit }, payload) => {
    return commit('addInstruction', payload.data)
  }
}

export const channels = {
  user: {
    error (socket, store, payload) {
      const actions = {
        0: () => {
          store.commit('openAuthModal')
        }
      }

      if (actions[payload.code]) {
        return actions[payload.code]()
      }

      store.commit('addErrorNotification', payload.message)
    },
    subscribe (socket, store, payload) {
      if (!payload.data) {
        return
      }

      if (payload.data && payload.data.data && payload.data.data.user) {
        const userdata = JSON.parse(localStorage.getItem('userData'))

        if (userdata.id === payload.data.data.user) {
          return
        }
      }

      if (actions[payload.data.event]) {
        return actions[payload.data.event](store, payload.data)
      }

      store.commit('addErrorNotification', `Event (${payload.data.event}) is not recognized!`)
    },
    unsubscribe () {
    },
    logOut () {
      return {
        event: 'orderadmin.user.logout',
        timestamp: new Date().toISOString()
      }
    },
    publish: {
      openOrder (entity) {
        return {
          event: 'products.order.opened',
          entityClass: 'Orderadmin\\Products\\Entity\\AbstractOrder',
          entity,
          timestamp: new Date().toISOString(),
          data: {}
        }
      },
      closeOrder (entity) {
        return {
          event: 'products.order.closed',
          entityClass: 'Orderadmin\\Products\\Entity\\AbstractOrder',
          entity,
          timestamp: new Date().toISOString(),
          data: {}
        }
      },
      openDeliveryRequest (entity) {
        return {
          event: 'delivery.services.request.opened',
          entityClass: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
          entity,
          timestamp: new Date().toISOString(),
          data: {}
        }
      },
      closeDeliveryRequest (entity) {
        return {
          event: 'delivery.services.request.closed',
          entityClass: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
          entity,
          timestamp: new Date().toISOString(),
          data: {}
        }
      },
      openPlace (entity, place) {
        return {
          event: 'storage.place.opened',
          entityClass: 'Orderadmin\\Storage\\Entity\\AbstractPlace',
          entity,
          timestamp: new Date().toISOString(),
          data: { place }
        }
      },
      closePlace (entity) {
        return {
          event: 'storage.place.closed',
          entityClass: 'Orderadmin\\Storage\\Entity\\AbstractPlace',
          entity,
          timestamp: new Date().toISOString(),
          data: {}
        }
      }
    }
  }
}

export const channelAdapters = {
  user: createUserChannel
}
