<template>
  <q-layout
    view="hHh lpR fFf"
    :class="`${getLayoutClass}`"
  >
    <q-header
      v-if="currentUser"
      @notification="handleNotification"
      elevated
    />

    <sidebar v-if="currentUser" />

    <help-sections-panel />

    <q-page-container
      :class="$q.dark.isActive ? '' : ''"
      class="q-pb-xl"
    >
      <router-view class="q-mt-sm" />

      <q-page-scroller
        expand
        position="bottom"
        :scroll-offset="150"
        :offset="[0, 0]"
        class="q-mt-md q-pt-lg"
      >
        <div class="col cursor-pointer q-pa-sm bg-light-blue-9 text-white text-center">
          {{ $t('Scroll back up...') }}
        </div>
      </q-page-scroller>
    </q-page-container>

    <div v-if="currentUser">
      <interaction ref="interactionModal" />

      <debug-modal ref="debugModal" />

      <pincode-modal ref="pincodeModal" />

      <chat-modal ref="chatDialog" />

      <heads />

      <auth-modal />
    </div>

    <div
      v-if="loadingNow && !isLoadingBottomHeader"
      class="q-notifications__list q-notifications__list--bottom fixed column no-wrap items-center q-ma-md"
    >
      <div
        role="alert"
        class="q-notification row items-stretch q-notification--standard text-white q-pa-sm"
      >
        <div class="q-notification__wrapper col relative-position border-radius-inherit row items-center">
          <div class="q-notification__content row items-center col">
            <svg
              focusable="false"
              width="1em"
              height="1em"
              viewBox="25 25 50 50"
              class="q-spinner q-spinner-mat q-notification__spinner"
            >
              <circle
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="currentColor"
                stroke-width="5"
                stroke-miterlimit="10"
                class="path"
              />
            </svg>

            <div class="q-notification__message col">
              {{ $t('Please wait...') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <barcode-input-modal ref="barcodeInputModal" />

    <confirm-modal ref="confirmModal" />

    <toast-notifications />
  </q-layout>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Components
import Notifications from './components/notifications/Notifications'
import ConfirmModal from './components/confirm-modal/ConfirmModal.vue'
import BarcodeInputModal from './components/barcode-input-modal/BarcodeInputModal.vue'

// Vuex
import { mapGetters } from 'vuex'

// Quasar
import { format } from 'quasar'

// Utils
import { InstructionsService } from './services/instructions.service'
import { UserService } from '@/apps/app/services'

export default {
  name: 'App',
  components: {
    Interaction: defineAsyncComponent(() => import('./components/notifications/Interaction')),
    PincodeModal: defineAsyncComponent(() => import('./components/pincode/PincodeModal')),
    DebugModal: defineAsyncComponent(() => import('./components/debug/DebugModal')),
    AuthModal: defineAsyncComponent(() => import('./components/auth-modal/AuthModal')),
    ChatModal: defineAsyncComponent(() => import('./components/chat/ChatModal')),
    ToastNotifications: Notifications,
    Heads: defineAsyncComponent(() => import('./components/heads/Heads')),
    QHeader: defineAsyncComponent(() => import('./components/header/Header')),
    Sidebar: defineAsyncComponent(() => import('./components/sidebar/Sidebar')),
    HelpSectionsPanel: defineAsyncComponent(() => import('./components/panels/HelpSectionsPanel')),
    ConfirmModal,
    BarcodeInputModal
  },
  data () {
    return {
      isStartedExecuting: false,
      instructionsService: null,
      interactionId: null,
      metatag: 'OrderAdmin',
      subscriber: null,
      authStarted: false
    }
  },
  meta () {
    return {
      title: this.metatag
    }
  },
  computed: {
    ...mapGetters([
      'isLoadingBottomHeader',
      'loadingNow',
      'isOpenMessageModal',
      'messageEntities',
      'currentUser',
      'appOptions',
      'instructions'
    ]),
    getLayoutClass() {
      return this.appOptions.theme + ' ' + (this.$q.dark.isActive ? '' : 'bg-gray-1')
    }
  },
  watch: {
    instructions (value) {
      if (this.isStartedExecuting) {
        return
      }

      this.isStartedExecuting = true
      this.instructionsService.execute(value)
        .finally(() => {
          this.isStartedExecuting = false
        })
    },
    isOpenMessageModal (val) {
      if (val) {
        this.$refs.chatDialog.open(this.messageEntities)
        return
      }

      this.$refs.chatDialog.close()
    }
  },
  mounted () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params))

    this.$q.dark.set(!!JSON.parse(localStorage.getItem('darkMode')))

    // Component is mounted before to load route
    // To set right metatag we need to set it after that but we don't know when it is loaded
    setTimeout(() => {
      this.metatag = this.getMetatag(this.$route)
    }, 1000)

    this.subscribeForRouteChange()

    const userdata = JSON.parse(localStorage.getItem('userData'))

    if (!userdata) {
      return
    }

    this.subscriber = this.$eventBus.subscribe('re-login', data => {
      this.$channels.user.unsubscribe()
      this.$centrifuge.disconnect()

      this.$eventBus.update('rules', { user: null, app: 'security' })
      this.$eventBus.update('rules', { user: null })

      UserService.logout()

      this.$router.push(`/login?refreshToken=` + data.user?.refreshToken)

      // if (!this.authStarted) {
      //   this.authStarted = true
      //
      //   this.$router.push(`/login?refreshToken=` + data.user?.refreshToken)
      //
      //   UserService.refreshAuth(data.user?.refreshToken).then(result => {
      //     const cookie = this.$q.cookies
      //         ? this.$q.cookies
      //         : Cookies
      //
      //     UserService.doLogin(result, cookie, this.appOptions, this.$eventBus).then(result => {
      //       this.authStarted = false
      //     })
      //   })
      // }
    })

    Promise.resolve(JSON.parse(localStorage.getItem('isLoginWithToken')))
      .then(isLoginWithToken => {
        return isLoginWithToken
          ? this.$refs.pincodeModal.showPromise()
          : true
      })
      .then(isOk => {
        if (!isOk) {
          return
        }

        this.$createSocket()

        this.$updateSocket(this.$centrifuge)
        this.$centrifuge.connect()
        this.$channels.user.subscribe()
      })
  },
  unmounted () {
    window.fcWidget.destroy()
    const userdata = JSON.parse(localStorage.getItem('userData'))

    if (userdata) {
      this.$centrifuge.disconnect()
      this.$channels.user.unsubscribe()
    }
  },
  methods: {
    subscribeForRouteChange () {
      this.$router.beforeEach((to, from, next) => {
        this.metatag = this.getMetatag(to)
        next()
      })
    },
    getMetatag (to) {
      const paths = to.path.split('/')
      const path = paths[paths.length - 1]

      if (!path) {
        return 'OrderAdmin'
      }

      const pathWithoutQuery = path.split('?')
      const newPath = pathWithoutQuery[pathWithoutQuery.length - 1]

      if (!isNaN(Number(newPath))) {
        return format.capitalize(`${paths[paths.length - 2].replace('-', ' ')} ${newPath}`)
      }

      return format.capitalize(newPath.replace('-', ' '))
    },
    /**
     * Get Action
     * Function is used from notification components via root component
     * Used to submit to components
     * Reason to use it is to not duplicate components
     */
    getAction (event) {
      const events = {
        'notifications.interaction.saved': data => {
          this.$refs.interactionModal.open(data.entity)
        },
        'notifications.request.saved': data => {
          this.$refs.debugModal.open(data)
        },
        'notifications.response.saved': data => {
          this.$refs.debugModal.open(data)
        },
        'application.comments.message.saved': data => {
          const entity = data.data.update.entity
          const entities = Object.keys(entity).reduce((acc, entityClass) => {
            acc.push({
              entityClass,
              entity: entity[entityClass]
            })
            return acc
          }, [])

          this.$refs.chatDialog.open(entities)
        }
      }

      return events[event]
    },
    handleNotification (data) {
      const action = this.getAction(data.event)

      if (action) {
        action(data)
      }
    }
  }
}
</script>
