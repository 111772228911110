import _ from 'lodash'


export class RouterUtils {
  _matrix
  _fullMatrix
  _rawMatrix
  _permissions

  _objects
  _routes
  _service

  allPermissions

  static get lodash () {
    return _
  }

  constructor (matrix, permissions = [], service) {
    this._service = service
    this._rawMatrix = matrix
    this._objects = {}
    this._routes = {}
    this.allPermissions = {}
    this._permissions = permissions
    this._fullMatrix = this.transform({ children: matrix }, true).children
    this._matrix = this.transform({ children: matrix }).children
  }

  usePermissions (permissions = []) {
    this._permissions = permissions
    this._objects = {}
    this._routes = {}
    this._fullMatrix = this.transform({ children: this._rawMatrix }, true).children
    this._matrix = this.transform({ children: this._rawMatrix }).children
  }

  _getPermissionsByEntities (entities) {
    return Object.entries(this.allPermissions).reduce((acc, [key, value]) => {
      if (entities.includes(key)) {
        acc[key] = value
      }

      return acc
    }, {})
  }

  getPermissions (entities) {
    const permissions = this._getPermissionsByEntities(entities)

    if (Object.keys(permissions).length === entities.length) {
      return Promise.resolve(this.allPermissions)
    }

    return this._service.getUserPermission(entities.filter(x => !permissions[x]))
      .then((newPermissions) => {
        this.allPermissions = { ...this.allPermissions, ...newPermissions }

        return this.allPermissions
      })
  }

  createRoutes (parentRoute, routes) {
    return routes.reduce((acc, route) => {
      const updatedRoute = {
        ...route,
        children: undefined
      }
  
      if (parentRoute) {
        updatedRoute.path = `${parentRoute.path}/${updatedRoute.path}`
      }
  
      if (updatedRoute.component || updatedRoute.redirect) {
        acc.push(updatedRoute)
      }
  
      if (route.children) {
        acc = [...acc, ...this.createRoutes(updatedRoute, route.children)]
      }
  
      return acc
    }, [])
  }
  
  getMenu () {
    return this._matrix.reduce((acc, route) => {
      if ((route.redirect && !route.ignoreRedirect) || route.path.includes(':')) {
        return acc
      }
  
      const updatedRoute = {
        ...route,
        header: 'generic',
        options:route.options || {
          styles: ''
        },
        component: undefined,
        entityClass: route.entityClass
          ? [route.entityClass]
          : []
      }
  
      if (updatedRoute.noChild) {
        updatedRoute.children = undefined
      }
  
      if (updatedRoute.children) {
        updatedRoute.children = updatedRoute.children.reduce((acc, x) => {
          if ((x.redirect && !x.ignoreRedirect) || x.path.includes(':')) {
            return acc
          }
  
          if (x.entityClass) {
            updatedRoute.entityClass.push(x.entityClass)
          }
  
          acc.push({
            ...x,
            header: 'generic',
            children: undefined,
            options: x.options || {
              styles: ''
            }
          })
  
          return acc
        }, [])
      }
      
  
      acc.push(updatedRoute)
  
      return acc
    }, [])
  }

  transformPath (route) {
    let path = route.path

    Object.entries(route.params).forEach(([key, value]) => {
      path = path.replaceAll(value, `:${key}`)
    })

    return path
  }
  
  transform (route, ignorePermissions) {
    const update = RouterUtils.lodash.cloneDeep(route)

    if (update.entityClass) {
      if (!this._objects[update.entityClass]) {
        this._objects[update.entityClass] = []
      }

      this._objects[update.entityClass].push(update)
    }

    if (ignorePermissions && update.path) {
      this._routes[update.path] = update
    }

    update.name = update.name || update.title

    if (update.children) {
      
      update.children = update.children.reduce((acc, x) => {
        if (x.entityClass && (!this._permissions.includes(x.entityClass) && !ignorePermissions)) {
  return acc
        }
        
        const symbol = update.path && update.path[update.path.length - 1] === '/'
          ? ''
          : '/'
  
        const value = { ...x }

        if (value.path[0] === '/') {
          value.path = value.path.slice(1)
        }

        const child = this.transform({ ...x, path: `${update.path || ''}${symbol}${value.path}` }, ignorePermissions)

        if (child.children && child.children.length <= 0 && !child.component) {
          return acc
        }

        acc.push(child)

        return acc
      }, [])
    }
    return update
  }

  getRouteByPath (path) {
    return this._routes[path]
  }
  
  getRoute (route, entity) {
    if (route.entityClass === entity) {
      return route
    }
  
    for (let i = 0; i < route.children.length; i++) {
      const element = route.children[i]
  
      if (element.entityClass === entity) {
        return element
      }
  
      if (element.children) {
        const r = this.getRoute(element, entity)
  
        if (r) {
          return r
        }
      }
    }
  
    return null
  } 

  getRouteByEntity (entity) {
    const route = this.getRoute({ children: RouterUtils.lodash.cloneDeep(this._matrix) }, entity)
    if (route) {
      route.children = route.children.reduce((acc, route) => {
        if (route.path.includes(':') || route.path.includes('entity')) {
          return acc
        }
  
        if (route.children && !route.hideChildren) {
          acc = [
            ...acc,
            ...route.children.filter(x => x.component && !x.path.includes(':') && !x.path.includes('entity'))
          ]
        }
  
        if (route.component) {
          acc.push(route)
        }
  
        return acc
      }, []).sort((a, b) => b.priority - a.priority)
    }
  
    return route
  }
} 