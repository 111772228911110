import { errorCatcher, handleResponse } from '../../services/handlers'
import { getToken } from '../../helpers'
import i18n from '@/i18n'
import services from '@/apps/app/services/services'
import { UserService } from '@/apps/app/services'

let lastUserdata = null
let lastUser = null

/**
 * Get Userdata
 *
 * @returns {object}
 */
function getUserdata () {
  const userdata = JSON.parse(localStorage.getItem('userData')) || lastUserdata

  // On logout we clean userdata but sometimes event is sended after that
  // So we save value of userdata
  lastUserdata = userdata
  return userdata
}

/**
 * Get User
 *
 * @returns {object}
 */
function getUser () {
  const user = JSON.parse(localStorage.getItem('user')) || lastUser

  // On logout we clean user but sometimes event is sended after that
  // So we save value of user
  lastUser = user
  return user
}

export function authHeader () {
  // return authorization header with jwt token
  let user = getUser()

  if (user && user.access_token) {
    return { 'Authorization': 'Bearer ' + user.access_token }
  } else {
    return {}
  }
}

function getNotificationForMessage (message) {
  if (message.entity['Orderadmin\\Products\\Entity\\AbstractOrder']) {
    return `${i18n.global.tc('New message for order')} ${message.entity['Orderadmin\\Products\\Entity\\AbstractOrder']}, ${i18n.global.tc('shop')} ${message.entity['Orderadmin\\Products\\Entity\\Shop']}`
  }

  return `No notification text for message ${message.id}`
}

const actions = {
  'application.comments.message.saved': ({ commit }, payload) => {
    const userdata = JSON.parse(localStorage.getItem('userData'))

    const message = {
      ...payload.data,
      id: payload.entity
    }

    if (userdata && userdata.id !== payload.data.author) {
      const data = {
        ...payload,
        message: getNotificationForMessage(message)
      }

      commit('addNotification', data)
      commit('addLocalNotification', data)
    }

    commit('addMessage', message)
  },
  'storage.movements.acceptance.saved': ({ commit, state }, payload) => {
    const fields = [
      'comment',
      'state'
    ]

    if (payload.data && (payload.data.state === 'new' && Object.keys(payload.data).length > 1)) {
      const message = `New PO ${payload.entity}`
      commit('addNotification', message)
      commit('addLocalNotification', { message })
    }

    const update = Object.keys(payload.data).reduce((acc, key) => {
      if (fields.includes(key)) {
        acc[key] = payload.data[key]
      }

      return acc
    }, {})

    if (state.acceptance && state.acceptance.id === Number(payload.entity)) {
      commit('updateAcceptance', update)
      commit('updateCleanAcceptance', { ...update })
    }

    let hasUpdate = false

    const items = state.acceptances.map(acceptance => {
      if (`${acceptance.id}` === `${payload.entity}`) {
        hasUpdate = true
        return { ...acceptance, ...update }
      }

      return acceptance
    })

    if (hasUpdate) {
      commit('setOrders', items)
    }

    return this
  },
  'products.order.saved': ({ commit, state }, payload) => {
    const fields = [
      'comment',
      'discountPrice',
      'email',
      'orderPrice',
      'paymentState',
      'recipientName',
      'state',
      'stateDescription',
      'totalPrice'
    ]

    if (payload.data && ((payload.data.state === 'pending' && Object.keys(payload.data).length === 1) || (payload.data.state === 'pending_queued' && Object.keys(payload.data).length > 1))) {
      commit('addOrderNotification', payload.entity)
    }

    const orderUpdate = Object.keys(payload.data).reduce((acc, key) => {
      if (fields.includes(key)) {
        acc[key] = payload.data[key]
      }

      return acc
    }, {})

    if (state.order && (state.order.id === Number(payload.entity))) {
      commit('updateOrder', orderUpdate)
      commit('updateCleanOrder', { ...orderUpdate })
    }

    let hasUpdate = false

    const orders = state.orders.map(order => {
      if (`${order.id}` === `${payload.entity}`) {
        hasUpdate = true
        const update = { ...order, ...orderUpdate }
        services.order.upsert(update)
        return update
      }

      return order
    })

    if (hasUpdate) {
      commit('setOrders', orders)
    }

    return this
  },
  'products.order.opened': ({ commit }, payload) => {
    return commit('addLockedOrder', payload.entity)
  },
  'products.order.closed': ({ commit }, payload) => {
    return commit('removeLockedOrder', payload.entity)
  },
  'delivery.services.request.saved': ({ commit, state }, payload) => {
    const fields = [
      'state',
      'outExtId',
      'price',
      'trackingNumber',
      'shippedByDocument',
      'senderName',
      'deliveryServiceStatus'
    ]

    const update = Object.keys(payload.data || {}).reduce((acc, key) => {
      if (fields.includes(key)) {
        acc[key] = payload.data[key]
      }

      return acc
    }, {})

    if (state.deliveryRequest && (state.deliveryRequest.id === Number(payload.entity)) && Object.values(update).length > 0) {
      commit('updateDeliveryRequest', update)
      commit('updateCleanDeliveryRequest', { ...update })
    }

    let hasUpdate = false

    const items = state.deliveryRequests.map(dr => {
      if (`${dr.id}` === `${payload.entity}`) {
        hasUpdate = true
        const updatedValue = { ...dr, ...update }
        services.deliveryRequest.upsert(update)
        return updatedValue
      }

      return dr
    })

    if (hasUpdate) {
      commit('setDeliveryRequests', items)
    }

    return this
  },
  'delivery.services.request.opened': ({ commit }, payload) => {
    return commit('addLockedDeliveryRequest', payload.entity)
  },
  'delivery.services.request.closed': ({ commit }, payload) => {
    return commit('removeLockedDeliveryRequest', payload.entity)
  },
  'notifications.interaction.saved': ({ commit }, payload) => {
    const code = `${payload.data.eav['integrations-asterisk-call'].call}`
    const codesWhichRemoveNotification = ['6', '4']

    if (codesWhichRemoveNotification.includes(code)) {
      return commit('removeNotificationByEntity', payload.entity)
    }

    const data = {
      ...payload,
      message: `${i18n.global.tc('Calling from client')} ${payload.data.eav['integrations-asterisk-call'].callerid} ${i18n.global.tc('for shop')} ${payload.shop.name}(${payload.shop.id})`
    }

    commit('addNotification', data)
    return commit('addLocalNotification', data)
  },
  'storage.place.opened': ({ commit }, payload) => {
    return commit('addStoragePlaceEvent', payload)
  },
  'storage.tasks.scan.place': ({ commit }, payload) => {
    return commit('addStoragePlaceEvent', payload)
  },
  'storage.place.closed': ({ commit }, payload) => {
    return commit('removeStoragePlaceEvent', payload.entity)
  },
  'storage.picking.task.updated': ({ commit }, payload) => {
    return commit('addStoragePickingTask', payload)
  },
  'storage.picking.task.saved': ({ commit }, payload) => {
    return commit('addStoragePickingTask', payload)
  },
  'storage.tasks.task.updated': ({ commit }, payload) => {
    return commit('addStoragePickingTask', payload)
  },
  'storage.tasks.task.saved': ({ commit }, payload) => {
    return commit('addStoragePickingTask', payload)
  },
  'orderadmin.user.logout': () => {
    UserService.logout()
  },
  'orderadmin.instructions.saved': ({ commit }, payload) => {
    return commit('addInstruction', payload.data)
  },
  'delivery.services.request.preprocessing.task.saved': ({ commit, state }, payload) => {
    const fields = [
      'state',
      'exportResult'
    ]

    const taskUpdate = Object.keys(payload.data).reduce((acc, key) => {
      if (fields.includes(key)) {
        acc[key] = payload.data[key]
      }

      return acc
    }, {})

    let hasUpdate = false
    if (state.task && (state.task.id === Number(payload.entity)) && state.task.state !== taskUpdate.state) {
      hasUpdate = true
    }

    if (hasUpdate) {
      let task = { ...state.task, ...payload.data }
      if (!payload.data.updated) {
        const currentTime = new Date()

        task.updated = {
          date: currentTime.toISOString(),
          timezone_type: 1,
          timezone: currentTime.getTimezoneOffset(),
        }
      }

      commit('setTask', task)
    }

    return this
  },
}

/**
 * Create Channels
 *
 * @param {Object} socket
 * @param {Object} channels
 * @param {Object} store
 * @param {Object} adapters
 *
 * @returns {Object}
 */
function createChannels (socket, channels, store = {}, adapters = {}) {
  const subcriptions = {}
  const newChannels = {}

  /**
   * Add Channel
   *
   * @param {String} key
   *
   * @returns {Void}
   */
  function addChannel (key) {
    newChannels[key] = {
      subscribe (fn) {
        const channel = typeof adapters[key] === 'function'
          ? adapters[key]()
          : adapters[key] || key

        // Plugin has different options for subscribe
        // Some of them as { subscribe () {}, error () {} } is broken
        // socket.on('subscribe', fn) also not work as expected
        subcriptions[key] = socket.newSubscription(channel, {
          getToken: getToken
        })
        subcriptions[key].subscribe()

        subcriptions[key].on('error', payload => {
          if (channels[key].error) {
            channels[key].error(socket, store, payload, fn)
          }
        })

        subcriptions[key].on('publication', payload => {
          if (payload.data && payload.data.data && payload.data.data.user) {
            const userdata = JSON.parse(localStorage.getItem('userData'))

            if (userdata.id === payload.data.data.user) {
              return
            }
          }

          if (actions[payload.data.event]) {
            return actions[payload.data.event](store, payload.data)
          } else {
            console.log('Event not defined')

            console.debug(payload)
          }
        })

        subcriptions[key].on('unsubscribe', payload => {
          if (channels[key].unsubscribe) {
            channels[key].unsubscribe(socket, store, payload, fn)
          }
        })
      },
      unsubscribe () {
        subcriptions[key].unsubscribe(key)
      },
      logOut (...value) {
        const data = channels[key].logOut(...value)

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: authHeader()
        }

        return fetch(`${window.appOptions.defaultServer || 'https://alpha.orderadmin.eu'}/apps/centrifugo/unsubscribe`, requestOptions)
          .then(data => handleResponse(data, 'POST'))
          .catch(errorCatcher)
      },
      publish (action, ...value) {
        if (!channels[key].publish[action]) {
          console.error(`Channel ${key} don't have publish action ${action}`)
          return
        }

        const data = channels[key].publish[action](...value)

        if (data.data) {
          const userdata = getUserdata()

          data.data.user = userdata.id
          data.data.email = userdata.email
        }

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: authHeader()
        }

        return fetch(`${window.appOptions.defaultServer || 'https://alpha.orderadmin.eu'}/apps/centrifugo/event`, requestOptions)
          .then(data => handleResponse(data, 'POST'))
          .catch(errorCatcher)
      }
    }
  }

  Object.keys(channels).forEach(addChannel)

  return newChannels
}

const SocketHelper = {
  install (app, options) {
    app.config.globalProperties.$channels = createChannels(options.socket, options.channels, options.store, options.adapters)

    app.config.globalProperties.$updateSocket = function (socket) {
      app.config.globalProperties.$channels = createChannels(socket, options.channels, options.store, options.adapters)
      return app.config.globalProperties.$channels
    }
  }
}

export default SocketHelper
