<template>
  <div
    class="q-notification row q-mx-none q-mt-md items-stretch q-notification--standard text-white"
    :class="`bg-${colors[item.type]}`"
    style="min-width: 300px;"
  >
    <div class="q-notification__wrapper col relative-position border-radius-inherit row items-center">
      <div class="row justify-content-between full-width card--clickable card--clickable-no-scale">
        <div
          class="col-10"
          @click="handleClick"
        >
          {{ item.message }}
        </div>

        <div class="col-1 row items-center justify-end">
          <q-icon
            name="close"
            @click="removeNotification(item.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Notification',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      timeout: null,
      delay: 5000,
      colors: {
        success: 'positive',
        warning: 'warning',
        error: 'negative',
        danger: 'negative'
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  mounted () {
    this.createSoundEffect(this.item.type)

    this.delay = this.getDelay(this.item.payload.event)

    if (this.delay === 0) {
      return
    }

    this.timeout = setTimeout(() => {
      this.removeNotification(this.item.id)
    }, this.delay)
  },
  unmounted () {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },
  methods: {
    ...mapMutations([
      'removeNotification',
      'openDebugModal',
      'openInteraction'
    ]),
    handleClick () {
      const action = this.$root.getAction(this.item.payload.event)

      if (!action) {
        return
      }

      action(this.item.payload)
    },
    createSoundEffect (type) {
      if (
        !this.appOptions.appSettings.sounds ||
        !this.appOptions.appSettings.sounds.routesWithSounds ||
        this.appOptions.appSettings.sounds.routesWithSounds.find(p => this.$route.path.includes(p))
      ) {
        const oldTypes = {
          error: 'danger'
        }

        const sound = this.appOptions.notificationsSounds[oldTypes[type] || type] || this.appOptions.notificationsSounds.default
        const audio = new Audio(sound)

        audio.play()
          .catch(error => {
            console.debug(error.message)
          })
      }
    },
    getDelay (event) {
      const delaysByEvent = {
        'notifications.interaction.saved': 30000
      }

      return delaysByEvent[event] !== undefined
        ? delaysByEvent[event]
        : 5000
    }
  }
}
</script>
