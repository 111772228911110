<template>
  <div
    class="q-notifications__list q-notifications__list--bottom fixed column no-wrap q-ma-md"
    :class="`items-${position} q-notifications__list--${verticalPosition}`"
  >
    <notification
      v-for="item in notifications"
      :key="item.id"
      :item="item"
    />

    <div
      v-if="notifications.length > 0"
      class="q-notification row q-mx-none q-mt-md items-stretch q-notification--standard bg-light-blue-1 text-dark"
      style="min-width: 300px;"
      @click="setNotifications([])"
    >
      <div class="q-notification__wrapper col relative-position border-radius-inherit row items-center">
        <div class="full-width card--clickable card--clickable-no-scale text-center text-subtitle1">
          {{ $t('Clear all notifications') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import Notification from './Notification'

export default {
  name: 'Notifications',
  components: {
    Notification
  },
  computed: {
    ...mapGetters([
      'notifications',
      'chatHeads'
    ]),
    verticalPosition () {
      return 'bottom'
    },
    position () {
      return this.$q.screen.width < 700
        ? 'center'
        : 'start'
    }
  },
  watch: {
    notifications: {
      handler (newValue) {
        if (newValue.length > 8) {
          this.setNotifications(newValue.slice(1))
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations([
      'setNotifications'
    ])
  }
}
</script>
